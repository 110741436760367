import { PrinterType } from '@/global-components/types'
import { create } from 'zustand'

interface PrintersState {
  printers: PrinterType[]
  setPrinters: (printers: PrinterType[]) => void
}

const usePrintersStore = create<PrintersState>((set) => ({
  printers: [],
  setPrinters: (printers: PrinterType[]) => {
    set({ printers })
  }
}))

export default usePrintersStore