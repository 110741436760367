import usePrintersStore from '@/context/usePrintersStore'
import api from '@/global-components/api'
import helpers from '@/global-components/components/helpers'
import { Button } from '@/global-components/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/global-components/components/ui/dialog'
import { Input } from '@/global-components/components/ui/input'
import { Label } from '@/global-components/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/global-components/components/ui/select'
import { PrinterType, PrintRequest } from '@/global-components/types'
import { useMutation } from '@apollo/client'
import { Loader } from 'lucide-react'
import React, { ChangeEvent, useState } from 'react'

interface SendToPrinterProps {
  children: JSX.Element
  printRequest: PrintRequest
}

const SendToPrinter: React.FC<SendToPrinterProps> = ({children, printRequest}) => {
  const [open, setOpen] = useState<boolean>(false)
  const { printers } = usePrintersStore()
  const [selectedPrinterId, setSelectedPrinterId] = useState<string | undefined>(undefined)
  const [quantity, setQuantity] = useState<number>(0)
  const [sending, setSending] = useState<boolean>(false)

  const [issuePrintToPrinterMutation] = useMutation(api.printers.mutations.ISSUE_PRINT_TO_PRINTER)

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setQuantity(Number(event.target.value))
	}

  const handleSend = () => {
    setSending(true)
    issuePrintToPrinterMutation({variables: {
      printerId: selectedPrinterId,
      fileId: printRequest.file.fileId,
      quantity: 1
    }})
      .then((result: any) => {
        console.log('sned file resutl: ', result)
        setSending(false)
        setOpen(false)
      })
      .catch((error: any) => {
        console.log('send file error: ', error)
        setSending(false)
      })
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(value: boolean) => {
        setOpen(value)
      }}>
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Send {printRequest.file.fileName} to a printer
            </DialogTitle>
          </DialogHeader>

          <Select onValueChange={setSelectedPrinterId}>
            <SelectTrigger className='basis-4/6'>
              <SelectValue placeholder='Choose a printer' />
            </SelectTrigger>
            <SelectContent>
              {printers?.map((printer: PrinterType, index: number) => (
                <SelectItem value={printer.printerId} key={index}>
                  <div className='flex items-center gap-2'>
                    <div className={'w-2 h-2 rounded-xl' + helpers.returnStatusColour(printer?.status || '')}></div>
                    {printer.name} - {printer.status}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <div className='flex flex-col gap-1'>
            <Label>
              Quantity
            </Label>
            <Input
              type='number'
              value={quantity}
              placeholder='Set Quantity'
              onChange={handleQuantityChange}
              className='basis-2/6'
            />
          </div>

          <DialogFooter>
						<div className='flex gap-1 items-center'>
              <Button className='flex gap-1 items-center' disabled variant='bwsecondary'>
                Cancel
              </Button>
							<Button variant='bwprimary' className='flex gap-1 items-center' disabled={sending} type='submit' onClick={handleSend}>
                {sending &&
                  <Loader className='h-3 w-3 animate-spin' />
                }
                Send and start print
							</Button>
						</div>
					</DialogFooter>
        </DialogContent>
    </Dialog>
  )
}

export default SendToPrinter