import { useState } from 'react';
import Filament, { FilamentProps } from '@/global-components/components/bw/filament/Filament'
import ProgressBar from '@/global-components/components/bw/progressBar/ProgressBar'
import './Printer.scss'
import PrinterDetail from './PrinterDetail';
import { AlertCircle, ZapOff, XCircle } from 'lucide-react'
import { Button } from "@/global-components/components/ui/button"
import api from '@/global-components/api'
import { useMutation } from '@apollo/client';

import { PrinterType, PrintStatus } from '@/global-components/types'



const Printer = ({ props }: { props: PrinterType }) => {
  const [printerDetailsOpen, setPrinterDetailsOpen] = useState<boolean>(false);
  const [toggledPrinterId, setToggledPrinterId] = useState<string | undefined>(undefined);
  let statusLightCssClasses = 'printer-status';
  switch(props.status.toLowerCase()) {
    case 'ready':
      statusLightCssClasses += ' bg-ui-confirmation-green';
      break;
    case 'operational':
      statusLightCssClasses += ' bg-ui-confirmation-green';
      break;
    case 'printing':
      statusLightCssClasses += ' bg-ui-confirmation-green animate-pulse';
      break;
    case 'inactive':
      statusLightCssClasses += ' bg-bw-grey';
      break;
    case 'unresponsive':
      statusLightCssClasses += ' bg-transparent';
      break;
    case 'pause':
      statusLightCssClasses += ' bg-bw-grey';
      break;
    case 'inoperational':
      statusLightCssClasses += ' bg-ui-denial-red';
      break;
    case 'error':
      statusLightCssClasses += ' bg-ui-denial-red';
      break;
  }

  const [deletePrinterMutation] = useMutation(api.printers.mutations.DELETE_PRINTER)

  const deletePrinter = () => {
    deletePrinterMutation({ variables: {printerId: props.printerId}})
      .then((result: any) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCurrentPrintStatus = (printstatusmodelSet: PrintStatus[] | undefined): PrintStatus | null => {
    if (printstatusmodelSet && printstatusmodelSet.length > 0) {
      const objectWithHighestStatusId = printstatusmodelSet.reduce((prev, current) => {
        return (Number(prev.printStatusId) > Number(current.printStatusId)) ? prev : current;
      });
      return objectWithHighestStatusId;
    } else {
      return null;
    }
  }

  const openPrinterDetails = () => {
    console.log('openeing printer details', printerDetailsOpen)
    if(!printerDetailsOpen && props.status !== 'UNRESPONSIVE') {
      setToggledPrinterId(props.printerId);
      setPrinterDetailsOpen(true);
    }
  }
  return (
    <div className={`printer relative ${props.status === 'UNRESPONSIVE' && 'opacity-70'}`}>
      {/* <Button variant="minimal" className='delete-printer' onClick={deletePrinter}>
        <XCircle className='icon h-4 w-4'></XCircle>
      </Button> */}
      <div className={`w-full h-full text-white text-xs flex flex-col justify-between p-2 rounded-md ${props.technicianReview ? 'bg-ui-denial-red' : 'bg-bw-green'}`}
        onClick={openPrinterDetails}>
        <div className={statusLightCssClasses}>
          {props.status === 'UNRESPONSIVE' ? <ZapOff className='h-4 w-4' /> : null}
        </div>
        {props.technicianReview && <AlertCircle className='w-4 h-4 relative self-center'/>}
        <div className='info flex flex-col gap-1'>
          <div className='file-name opacity-30'>
            {/* props print quantity + props print file name */}
          </div>
          {props.status === 'PRINTING' 
            ? <div className='text-bw-pale-sage flex flex-col gap-1'>
                <div className='text-xs max-w-[70%] truncate opacity-30'>{getCurrentPrintStatus(props.printstatusmodelSet)?.job.fileName}</div>
                <ProgressBar height={2} progress={Math.round(getCurrentPrintStatus(props.printstatusmodelSet)?.percentage || 0)}/>
                {getCurrentPrintStatus(props.printstatusmodelSet)?.percentage}
              </div>
            : null
          }
          <div className='printer-info flex gap-1 justify-between items-center'>
            <div className={`printer-name shrink gap-1 truncate ${props.status === 'UNRESPONSIVE' && 'opacity-70'}`}>
              {props.printerId} - {props.name}
            </div>
            <div className='filaments flex shrink-0 gap-1'>
              <div className='active'>
                <Filament color={props.activeFilament.colour.hexCode || '#333333'} active={true} small={true} />
              </div>
              <div className='secondary'>
                <Filament color={props.inactiveFilament.colour.hexCode || '#333333'} active={true} small={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrinterDetail open={printerDetailsOpen} printerId={toggledPrinterId} callbackPrinterDetailsOpen={() => setPrinterDetailsOpen(false)}/>
    </div>
  )
}

export default Printer;