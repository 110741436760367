import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import api from '@/global-components/api'
import { ScheduleItem, PrinterType, PrintRequest } from '@/global-components/types'
import { useParams } from "react-router-dom";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/global-components/components/ui/table';
import { Button } from '@/global-components/components/ui/button';
import { Check, Edit, Edit2, Edit3, PlusCircle, X } from 'lucide-react';
import helpers from '@/global-components/components/helpers'
import { Spin } from 'react-cssfx-loading';
import SendToPrinter from './SendToPrinter';
import { Input } from '@/global-components/components/ui/input';
import { Label } from '@/global-components/components/ui/label';
import UpdateProduced from './UpdateProduced';

const PrintRequests = () => {
  const [printRequests, setPrintRequests] = useState<PrintRequest[]>([])
  const [deletingPrintRequests, setDeletingPrintRequests] = useState<string[]>([])
  const printRequestsQuery = useQuery(api.printRequests.queries.GET_ALL_PRINT_REQUESTS, {
    pollInterval: 3000,
  })
  const [deletePrintRequestMutation] = useMutation(api.printRequests.mutations.DELETE_PRINT_REQUEST)

  const deletePrintRequest = (id: string) => {
    setDeletingPrintRequests(prev => [...prev, id]);
    deletePrintRequestMutation({ variables: { requestId: id}})
      .then((result: any) => {
        setDeletingPrintRequests(prev => prev.filter(item => item !== id));
        setPrintRequests(prev => prev.filter(item => item.requestId !== id))
        printRequestsQuery.refetch()
      })
      .catch((error: any) => {  
        console.log(error)
      })
  }

  useEffect(() => {
    if (printRequestsQuery.data) {
      setPrintRequests(printRequestsQuery.data.printRequests)
    }
  }, [printRequestsQuery.data])
  return (
    <div className='print-requests p-4 '>
      <div className='print-requests p-4 rounded-md bg-white'>
        <Table className=''>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]">ID</TableHead>
              <TableHead className="w-[150px]">Produced</TableHead>
              <TableHead className='w-[200px]'>Requested By</TableHead>
              <TableHead className='w-[200px]'>Created at</TableHead>
              <TableHead className='truncate'>File</TableHead>
              <TableHead className='w-[250px]'>Filament</TableHead>
              <TableHead className='text-right'></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {printRequests.map((req: PrintRequest, index: number) => {
              return (
                <TableRow className={`${deletingPrintRequests.find(id => id === req.requestId) ? 'opacity-30 pointer-events-none' : ''}}`}>
                  <TableCell>{req.requestId}</TableCell>
                  <TableCell>
                    <div className={`flex gap-1 items-center ${req.produced === req.quantity && 'text-ui-confirmation-green'}`}>
                      <span className={`${req.produced === req.quantity ? 'font-bold' : 'opacity-70'}`}>{req.produced} / </span> <b>{req.quantity}</b>
                      <UpdateProduced requestId={req.requestId} produced={req.produced} refetchRequest={() => printRequestsQuery.refetch()}>
                        <Button variant="ghost" size='smallicon' className={`text-bw-green p-0 m-0 ${req.quantity === req.produced && 'opacity-30'}`}>
                          <PlusCircle className='h-4 w-4'/>
                        </Button>
                      </UpdateProduced>
                      {/* {req.produced === req.quantity 
                        && <div className='p-1'>
                          <Check className='h-4 w-4 text-ui-confirmation-green' />
                        </div>
                      } */}
                    </div>
                  </TableCell>
                  <TableCell>{req.requestedBy.firstName}</TableCell>
                  <TableCell>{helpers.formatDateToDDMMYY(req.issuedAt)}</TableCell>
                  <TableCell className='truncate'>
                    <a href={req.file.presignedUrl} target='_blank' className='underline truncate'>{req.file.fileName}</a>
                  </TableCell>
                  <TableCell>{req.filament.material.displayName} - {req.filament.colour.displayName}</TableCell>
                  <TableCell className='flex justify-end'>
                    <div className='flex items-center gap-1'>
                      <SendToPrinter printRequest={req}>
                        <Button variant='bwsecondary'>  
                          Send to printer
                        </Button>
                      </SendToPrinter>
                      <Button variant='bwprimary' disabled>  
                        Schedule
                      </Button>
                      <Button 
                        variant='destructiveghost' 
                        onClick={() => deletePrintRequest(req.requestId)}>
                        {deletingPrintRequests.find(id => id === req.requestId) ?
                          <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
                          :
                          <X className='h-4 w-4' />
                        }
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default PrintRequests